import styles from "../styles.module.scss";
import AkpIcon from "./icons";
import { useState } from "react";
import PricingCardDesc from "./pricingCardDesc";

function PricingCard(props) {

  const CardFlip = false;
  const [isFlipped, setFlipped] = useState("false");
  const ToggleClass = () => {
    setFlipped(!isFlipped);
  };
  return (
    <div className={`${styles.akpPricingCardCont}`}
    >
      <div className={`${styles.akpPricingCard} ${
        props.CardInfo ? `${styles.akpFlipCard}` : ``
      } ${
          isFlipped ? `${styles.flippedOut}` : `${styles.flippedIn}`
        }`} >
        <div className={styles.akpPricingCardFront}>
          <div className={styles.akpPricingCardIcon}>
            <AkpIcon IconName={props.CardIcon} />
          </div>
          <div className={styles.akpPricingCardTitle}>{props.CardName}</div>
          {props.OldPrice && props.OldPrice.trim() !== "" && (
            <div className={styles.akpPricingCardOld}>{props.OldPrice}</div>
          )}
          <div className={styles.akpPricingCardPriceContainer}>
            <div className={styles.akpPricingCardPricePrefix}>
              {props.CardPricePrefix}
            </div>
            <div className={styles.akpPricingCardPrice}>
              {props.CardPrice}
            </div>
            <div className={styles.akpPricingCardPriceSuffix}>
              {props.CardPriceSuffix}
            </div>
          </div>
          {props.CardInfo && props.CardInfo.trim() !== "" && (
            <button className={styles.akpPricingCardNote} onClick={ToggleClass}>Lisätietoa
              <AkpIcon IconName="Refresh" />

            </button>
          )}
        </div>
       {props.CardInfo ? 
          <div className={styles.akpPricingCardBack}>
            <PricingCardDesc CardInfo={props.CardInfo}/>
            <button className={styles.akpPricingCardNote} onClick={ToggleClass}>Takaisin
              <AkpIcon IconName="Refresh" />
            </button>
          </div>
        : ``
        }
      </div>
    </div>
  );
}

export default PricingCard;
