import styles from "./styles.module.scss";
import Header from "./components/header";
import Hero from "./components/hero";
import Info from "./components/info";
import Pricing from "./components/pricing";
import Contact from "./components/contact";
import About from "./components/about";
import Footer from "./components/footer";

function App() {

  return (
    <div className={styles.akpRoot}>
      <Header />
      <Hero />
      <Info />
      <Pricing />
      <Contact />
      <About />
      <Footer />
    </div>
  );
}

export default App;
