import styles from "../styles.module.scss";
import PricingCard from "./pricingCard";
import PricingDescItem from "./pricingDescItem";


function Pricing() {
  return (
    <div className={`${styles.akpPricing} ${styles.akpFullBlock}`}>
      <div
        className={`${styles.akpBlock} ${styles.akpPricingCont}`}
        id="pricing"
      >
        <h1>Hinnasto</h1>
        <div className={styles.akpPricingList}>
          <PricingCard
            CardIcon="SmallHouse"
            CardName="Yksiö / kaksio"
            CardPrice="150 €"
          />
          <PricingCard
            CardIcon="MediumHouse"
            CardName="Kolmio / neliö"
            CardPrice="170 €"
          />
          <PricingCard
            CardIcon="LargeHouse"
            CardName="Isommat asunnot"
            CardPrice="210 €"
          />
          <PricingCard
            CardIcon="Factory"
            CardName="Muut kiinteistöt"
            CardPricePrefix="alk."
            CardPrice="200 €"
          />
        </div>
        <h4>Lisäpalvelut:</h4>
        <div className={styles.akpPricingList}>
          <PricingCard
            CardIcon="Drone"
            CardName="Ilmakuvaus"
            CardPrice="+60 €"
            CardInfo="Drone"
          />

          <PricingCard
            CardIcon="Movie"
            CardName="Asuntovideo"
            CardPrice="+90 €"
            CardInfo="Movie"
          />
          <PricingCard
            CardIcon="Decor"
            CardName="Virtuaalisisustus"
            CardPrice="+40 €"
            CardPriceSuffix="/kuva"
            CardInfo="Decor"
          />
          <PricingCard
            CardIcon="Blueprint"
            CardName="Pohjapiirros"
            CardPrice="+100 €"
            CardNote="Lisätietoa"
            CardInfo="Blueprint"
          />
        </div>
        <h4>Kaikki hinnat sisältävät:</h4>
        <div className={styles.akpPricingDesc}>
          <PricingDescItem ItemText="Kattava valokuvaus asunnosta" />
          <PricingDescItem ItemText="Kuvien jälkikäsittely tietokoneella" />
          <PricingDescItem ItemText="Toimitus kahden arkipäivän sisällä kuvauksesta" />
          <PricingDescItem ItemText="Matkakulut 10km säteellä Jyväskylästä" />
          <PricingDescItem ItemText="Latauslinkki ja vapaat käyttöoikeudet valmiisiin kuviin" />
          <PricingDescItem ItemText="Tarvittavat ALV- ja muut maksut" />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
