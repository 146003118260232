import styles from "../styles.module.scss";

function ContactAlt() {
  return (
    <div className={styles.akpContactAlt}>
      <h4>
        Eikö lomakkeet nappaa?
        <br />
        Voit ottaa halutessasi yhteyttä myös näillä:
      </h4>

      <div className={styles.akpContactAltItem}>
        <div className={`${styles.akpContactAltItemIcon} ${styles.wa}`}>
          <svg
            width="28"
            height="30"
            viewBox="0 0 28 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.7819 4.86684C21.167 2.20971 17.6846 0.748291 13.9838 0.748291C6.34513 0.748291 0.129317 7.04948 0.129317 14.7931C0.129317 17.2668 0.765877 19.6835 1.97659 21.8155L0.0107422 29.091L7.35614 27.1361C9.37815 28.2559 11.656 28.8443 13.9776 28.8443H13.9838C21.6163 28.8443 27.9694 22.5431 27.9694 14.7994C27.9694 11.0478 26.3968 7.52397 23.7819 4.86684ZM13.9838 26.4782C11.9119 26.4782 9.88366 25.9151 8.11751 24.8522L7.69938 24.5992L3.34332 25.7569L4.5041 21.4486L4.22951 21.0057C3.07497 19.1457 2.46961 17.0011 2.46961 14.7931C2.46961 8.35907 7.63697 3.12073 13.9901 3.12073C17.0668 3.12073 19.9563 4.33541 22.1281 6.54336C24.2999 8.75131 25.6354 11.6805 25.6291 14.7994C25.6291 21.2398 20.3307 26.4782 13.9838 26.4782ZM20.2995 17.7349C19.9563 17.5578 18.2525 16.71 17.9343 16.5962C17.616 16.476 17.3851 16.419 17.1542 16.7733C16.9233 17.1276 16.2617 17.9121 16.0558 18.1525C15.8561 18.3866 15.6501 18.4182 15.3069 18.2411C13.2724 17.2098 11.9369 16.4 10.5951 14.0656C10.2394 13.4456 10.9508 13.4899 11.6124 12.1486C11.7247 11.9146 11.6685 11.7121 11.5811 11.535C11.4938 11.3578 10.8011 9.63069 10.514 8.92845C10.2331 8.24519 9.94606 8.34009 9.73388 8.32744C9.53417 8.31478 9.30326 8.31478 9.07235 8.31478C8.84145 8.31478 8.467 8.40335 8.14872 8.75131C7.83044 9.10559 6.93801 9.95335 6.93801 11.6805C6.93801 13.4076 8.17992 15.0778 8.34842 15.3119C8.52317 15.546 10.7886 19.0888 14.2647 20.6135C16.4614 21.5751 17.3227 21.6574 18.421 21.4929C19.0888 21.3917 20.468 20.6451 20.7551 19.8227C21.0422 19.0002 21.0422 18.298 20.9548 18.1525C20.8737 17.9943 20.6428 17.9058 20.2995 17.7349Z"
              fill="white"
            />
          </svg>
        </div>
        <div className={styles.akpContactAltItemText}>
          <div className={styles.akpContactAltItemLabel}>Whatsapp</div>
          <a
            aria-label="Whatsapp linkki"
            target="_blank"
            href="https://wa.me/358405968610"
          >
            Paina tästä >
          </a>
        </div>
      </div>
      <div className={styles.akpContactAltItem}>
        <div className={`${styles.akpContactAltItemIcon} ${styles.phone}`}>
          <svg width="20" height="35" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.04397 5.66629L3.00545 30.5892C3.13544 32.241 4.57983 33.4746 6.23158 33.3446L16.2008 32.56C17.8525 32.43 19.0861 30.9856 18.9561 29.3339L16.9946 4.41094C16.8647 2.7592 15.4203 1.52557 13.7685 1.65557L3.79935 2.44016C2.1476 2.57016 0.913976 4.01454 1.04397 5.66629Z" stroke="white" strokeWidth="2"/>
            <rect x="6.90771" y="3.7002" width="4" height="1.5" rx="0.75" transform="rotate(-4.5 6.90771 3.7002)" fill="white"/>
          </svg>
        </div>
        <div className={styles.akpContactAltItemText}>
          <div className={styles.akpContactAltItemLabel}>Puhelin</div>
          040 5968610
        </div>
      </div>
      <div className={styles.akpContactAltItem}>
        <div className={`${styles.akpContactAltItemIcon} ${styles.email}`}>
          @
        </div>
        <div className={styles.akpContactAltItemText}>
          <div className={styles.akpContactAltItemLabel}>Sähköposti</div>
          myynti@ks-asuntokuvaus.fi
        </div>
      </div>
    </div>
  );
}

export default ContactAlt;
