import React from "react"
import styles from "../styles.module.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import showcase1 from "../media/showcase1.jpg";
import showcase1s from "../media/showcase1-s.jpg";
import drone2 from "../media/drone2.jpg";
import drone2s from "../media/drone2-s.jpg";
import decor1 from "../media/virtuaalisisustus-tyhja.jpg";
import decor2 from "../media/virtuaalisisustus-sisustettu.jpg";
import showcase6 from "../media/showcase6.png";
import showcase6s from "../media/showcase6s.png";


export default function PricingCardDesc(props) {
    const [index, setIndex] = React.useState(-1);
    if (props.CardInfo === "Drone") {
        const slides=[
            { src: showcase1 },
            {src: drone2}
            ]
      return (
            <div className={styles.akpPricingCardBackContent}>
                <div className={styles.akpPricingCardTitle}>Ilmakuvaus</div>
                <div className={styles.akpPricingCardMedia}>
                    <img
                      src={showcase1s}
                      onClick={({ index: current }) => setIndex(0)}
                      alt="Esimerkki ilmakuvasta"
                      />
                    <img
                      src={drone2s}
                      onClick={({ index: current }) => setIndex(1)}
                      alt="Esimerkki sijaintikuvasta"
                      />
                </div>
                <div className={styles.akpPricingCardDescription}>
                    <p>Asunnosta otetaan ilmakuvat eri kulmista ja 
                    asiakkaan toiveesta myös kuva kauempaa, jossa näkyy asunnon sijainti suhteessa lähimpään keskukseen.
                    Ilmakuvaus toteutetaan aina paikallisten lentosäännösten puitteissa.</p>
                    <p>
                    <b>Erikseen ilman asuntokuvausta lisämaksu 100€.</b> 
                    </p>
                </div>
                <Lightbox
                    open={index >= 0}
                    close={() => setIndex(-1)}
                    index={index}
                    slides={slides}
                    carousel={{ finite: slides.length <= 1 }}
                    render={{
                        buttonPrev: slides.length <= 1 ? () => null : undefined,
                        buttonNext: slides.length <= 1 ? () => null : undefined,
                    }}
                    />
            </div>
          );
      }
      if (props.CardInfo === "Movie") {
            return (
              <div className={styles.akpPricingCardBackContent}>
                  <div className={styles.akpPricingCardTitle}>Asuntovideo</div>
                  <div className={`${styles.akpPricingCardMedia} ${styles.akpPricingCardVideo}`}>
                    <iframe width="100%" src="https://www.youtube.com/embed/a8qe0_WZ-9s?si=77roZzNvaXpY_KqI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                  <div className={styles.akpPricingCardDescription}>
                      <p>Asunnosta kuvataan video, joka näyttää kamera-ajona kaikki asunnon huoneet. Jos tilattu myös ilmakuvaus, voidaan videoon sisällyttää kuvamateriaalia ilmasta.</p>
                      <p>
                      <b>Erikseen ilman asuntokuvausta lisämaksu 100€.</b> 
                      </p>
                  </div>
              </div>
            );
        }
        if (props.CardInfo === "Decor") {
            const slides=[
                { src: decor1 },
                {src: decor2}
                ]
          return (
                <div className={styles.akpPricingCardBackContent}>
                    <div className={styles.akpPricingCardTitle}>Virtuaalisisustus</div>
                    <div className={styles.akpPricingCardMedia}>
                        <img
                          src={decor1}
                          onClick={({ index: current }) => setIndex(0)}
                          alt="Esimerkki huoneesta ilman virtuaalisisustusta"
                          />
                        <img
                          src={decor2}
                          onClick={({ index: current }) => setIndex(1)}
                          alt="Esimerkki huoneesta virtuaalisisustustuksella"
                          />
                    </div>
                    <div className={styles.akpPricingCardDescription}>
                        <p>Asunto sisustetaan virtuaalisesti tekoälypalvelun avulla. Virtuaalisisustuksen avulla saa edustavia myyntikuvia tyhjästä tilasta. Potentiaalisen ostajan on helpompi nähdä tilan mahdollisuuksia sisustusesimerkin avulla.</p>
                    </div>
                    <Lightbox
                        open={index >= 0}
                        close={() => setIndex(-1)}
                        index={index}
                        slides={slides}
                        carousel={{ finite: slides.length <= 1 }}
                        render={{
                            buttonPrev: slides.length <= 1 ? () => null : undefined,
                            buttonNext: slides.length <= 1 ? () => null : undefined,
                        }}
                        />
                </div>
              );
          }
          if (props.CardInfo === "Blueprint") {
            const slides=[
                { src: showcase6 }
                ]
          return (
                <div className={styles.akpPricingCardBackContent}>
                    <div className={styles.akpPricingCardTitle}>Pohjapiirros</div>
                    <div className={styles.akpPricingCardMedia}>
                        <img
                          src={showcase6s}
                          onClick={({ index: current }) => setIndex(0)}
                          alt="Esimerkki pohjapiirustuksesta"
                          />
                    </div>
                    <div className={styles.akpPricingCardDescription}>
                        <p>Vanhasta paperisesta tai sähköisestä pohjapiirroksesta toteutetaan suunnitteluohjelmalla puhtaaksipiirretty nykyaikainen versio. </p>
                        <b>Erikseen ilman asuntokuvausta lisämaksu 50€.</b> 
                    </div>
                    <Lightbox
                        open={index >= 0}
                        close={() => setIndex(-1)}
                        index={index}
                        slides={slides}
                        carousel={{ finite: slides.length <= 1 }}
                        render={{
                            buttonPrev: slides.length <= 1 ? () => null : undefined,
                            buttonNext: slides.length <= 1 ? () => null : undefined,
                        }}
                        />
                </div>
              );
          }
  }
  