import styles from "../styles.module.scss";
import AkpButton from "./button";
import React from "react";


class Copyright extends React.Component {
  render() {
    return <div className={styles.akpFooterText}>© Keski-Suomen Asuntokuvaus {(new Date().getFullYear())}</div>;
  }
}

function Footer() {
  return (
    <div className={`${styles.akpFooter} ${styles.akpFullBlock}`}>
      <div className={`${styles.akpFooterCont} ${styles.akpBlock}`}>
        <AkpButton secondary ButtonText="Takaisin ylös" />
        <a className={styles.akpFooterLink} href="https://www.valokuvaajat.fi">Valokuvaajat.fi -jäsen</a>
        <Copyright />
      </div>
    </div>
  );
}

export default Footer;
