import React, { useEffect, useState } from "react";
import logo from "../logo.svg";
import logoWhite from "../logo-white.svg";
import styles from "../styles.module.scss";
import Navigation from "./navigation";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Header() {

  const [withBG, setWithBG] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setWithBG(window.scrollY > 80)
      );
    }
  }, []);

  return (
    <div className={`${styles.akpHeader} ${styles.akpFullBlock} ${withBG ? `${styles.withBG}` : ""}`}>
      <div className={`${styles.akpHeaderCont} ${styles.akpBlock}`}>
        <button
          type="button"
          onClick={scrollToTop}
          aria-label="Keski-Suomen Asuntokuvaus logo painike"
        >
          <img
            src={withBG ? logo : logoWhite}
            className={styles.akpLogo}
            alt="logo"
            aria-label="Keski-Suomen Asuntokuvaus logo"
          />
        </button>
        <Navigation />
      </div>
    </div>
  );
}

export default Header;
