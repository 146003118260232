import styles from "../styles.module.scss";
import NavigationLink from "./navigationLink";
import Carousel from "./carousel"

function Hero() {
  return (
    <div className={`${styles.akpHero} ${styles.akpFullBlock}`}>
      <Carousel></Carousel>
      <div className={`${styles.akpBlock} ${styles.akpHeroCont}`}>
        <div className={styles.akpHeroText}>
          <h1>
            Ammattimaiset <br />
            asunto- ja <br />
            kiinteistökuvat <br />
            Jyväskylässä
          </h1>
        </div>
        <NavigationLink button LinkText="Ota yhteyttä" LinkTarget="contact" />
      </div>
    </div>
  );
}

export default Hero;
