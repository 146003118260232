import { useEffect, useState } from "react";
import styles from "../styles.module.scss";

import hero1 from "../media/hero1.jpg";
import hero2 from "../media/hero2.jpg";
//import hero3 from "../media/hero3.jpg";
import hero4 from "../media/hero4.jpg";
import hero5 from "../media/hero5.jpg";


const Carousel = () => {
  const data = [{url: hero1}, {url: hero2}, {url:hero4}, {url:hero5}];
  const [CarouselIndex, setCarouselIndex] = useState(0);
  const CarouselHandler = () => {
    if (CarouselIndex === data.length - 1) {
      return setCarouselIndex(0);
    }
    return setCarouselIndex(CarouselIndex + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      CarouselHandler();
    }, 8000);
    return () => clearInterval(interval);
  });

  return (
    <div className={styles.akpCarousel}>
      { data.map((item, index) => {
        return <div className={`${styles.akpCarouselItem} ${
          index === CarouselIndex ? `${styles.akpActive}` : ""
        }`} 
        style={{backgroundImage: `url(${item.url})`,
        zIndex: index <= CarouselIndex ? "1" : "0"
        }}
        key={index}>
          </div>})
      }
    </div>
  )
}

export default Carousel;
