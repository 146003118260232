import React from "react";
import styles from "../styles.module.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import showcase1 from "../media/showcase1.jpg";
import showcase1s from "../media/showcase1-s.jpg";
import showcase2 from "../media/showcase2.jpg";
import showcase2s from "../media/showcase2s.jpg";
import showcase3 from "../media/showcase3.jpg";
import showcase3s from "../media/showcase3s.jpg";
import showcase4 from "../media/showcase4.jpg";
import showcase4s from "../media/showcase4s.jpg";
import showcase5 from "../media/showcase5.jpg";
import showcase5s from "../media/showcase5s.jpg";
import showcase6 from "../media/showcase6.jpg";
import showcase6s from "../media/showcase6s.jpg";

function InfoShowcase() {
  const slides=[
    { src: showcase1 },
    { src: showcase2 },
    { src: showcase3 },
    { src: showcase4 },
    { src: showcase5 },
    { src: showcase6 },
    ]
  const [index, setIndex] = React.useState(-1);
  return (
    <div className={styles.akpInfoShowcase}>
      <img
        src={showcase1s}
        alt="Esimerkki ilmakuvasta"
        onClick={({ index: current }) => setIndex(0)}
      />
      <img
        src={showcase2s}
        alt="Esimerkkikuva olohuoneesta"
        onClick={({ index: current }) => setIndex(1)}
      />
      <img
        src={showcase3s}
        alt="Esimerkkikuva olohuoneesta"
        onClick={({ index: current }) => setIndex(2)}
      />
      <img
        src={showcase4s}
        alt="Esimerkkikuva terassilta"
        onClick={({ index: current }) => setIndex(3)}
      />
      <img
        src={showcase5s}
        alt="Esimerkkikuva pesutiloista"
        onClick={({ index: current }) => setIndex(4)}
      />
      <img
        src={showcase6s}
        alt="Esimerkki sijaintikuvasta"
        onClick={({ index: current }) => setIndex(5)}
      />
      <Lightbox
                        open={index >= 0}
                        close={() => setIndex(-1)}
                        index={index}
                        slides={slides}
                        carousel={{ finite: slides.length <= 1 }}
                        render={{
                            buttonPrev: slides.length <= 1 ? () => null : undefined,
                            buttonNext: slides.length <= 1 ? () => null : undefined,
                        }}
                        />
    </div>
  );
}

export default InfoShowcase;
