import styles from "../styles.module.scss";
import author from "../media/author.jpg";

function About() {
  return (
    <div className={`${styles.akpAbout} ${styles.akpFullBlock}`}>
      <div className={`${styles.akpAboutCont} ${styles.akpBlock}`}>
        <div className={styles.akpAboutText}>
          <h1>Keski-Suomen Asuntokuvaus?</h1>
          <p>
            Moi, mä olen Olli. Olen tehnyt valokuvaustöitä sivutoimisesti jo
            pidemmän aikaa. Asuntokuvauksesta on kertynyt usean vuoden kokemus 
            hyvin monen tyylisistä kohteista pienistä kesämökeistä uusiin omakotitaloihin.
          </p>
          <p>
            Asuntokuvaus on genrenä lähellä sydäntä, koska yksityiskohtiin ja
            laatuun panostamalla saa samasta tilasta luotua niin paljon
            kutsuvamman. Loppuun asti hiottu kuvapaketti erottaa aidosti
            kiinnostavan ja massaan hukkuvan ilmoituksen.
          </p>
          <p>
            Tärkeintä itselleni tässä hommassa on tyytyväinen asiakas, joka
            kokee saaneensa rahoilleen vastinetta. Tehdäänkö sinusta yksi
            niistä?
          </p>
        </div>
        <div className={styles.akpAuthor}>
          <img src={author} aria-label="Author"></img>
        </div>
      </div>
    </div>
  );
}

export default About;
